import React, { useEffect, useReducer, useState } from 'react'
import { Vote } from '../../interfaces/Interfaces';
import { LoadingOverlay, Stack, Text } from '@mantine/core';
import { Toggle } from './Toggle'
import { getFAQPage, voteOnFAQ } from '../../api/api';
import { useParams } from 'react-router-dom';

const initVoteState:Vote = {
  upvotes: 0,
  downvotes: 0
}

const reducer = (state: Vote, {vote, type}: any) => {
  switch (type) {
    case 'set':
      return { upvotes: state.upvotes + vote , downvotes: state.downvotes - vote }
    case 'update':
      if(vote === 1) return { upvotes: state.upvotes + 1, downvotes: state.downvotes -1 }
      else return { upvotes: state.upvotes -1, downvotes: state.downvotes +1 }
    case 'init':
      return {upvotes: vote.upvotes, downvotes: vote.downvotes}
    default:
      return state;
  }
}

export const Voting:React.FC = () => {

  const { slug } = useParams<string>();
  
  const getStoredVote:any = (slug:string) => localStorage.getItem(`cb-${slug}-accountVote`);
  const setStoredVote = (slug:string | undefined, vote:number) => localStorage.setItem(`cb-${slug}-accountVote`, String(vote));

  const [{upvotes,downvotes}, dispatch] = useReducer(reducer,initVoteState)

  const [vote, setVote] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(true);
  const [id, setId] = useState<string>('');

  const onSetVote = (currentVote:number) => {

    //const storedVote = Number(getStoredVote()?.split(' ')[1]);

    if(vote === currentVote) return;

    if( vote !== currentVote){
      dispatch({ type: vote === 0 ? 'set' : 'update' , vote: currentVote });
      voteOnFAQ( id, currentVote, vote === 0 ? 'set' : 'update');
    }

    setVote(currentVote);
    setStoredVote(slug, currentVote);

  }

  useEffect(() => {

    if(slug){

      const storedVote = JSON.parse( getStoredVote(slug) );

      if ( storedVote !== null ) setVote(Number( storedVote ) );

      (async () => {

        try{
          const { data } = await getFAQPage(slug);
          setId(data._id);
          const { upvotes, downvotes } = data;
          dispatch({ type: 'init', vote:{ upvotes, downvotes } });
  
        }catch(e){
          console.log(e)
        }finally{
          setLoading(false);
        }
  
      })();

    }

  }, [ slug ]);

  return (
    
    <Stack align={'center'}>
      { loading && <div style={{ width: 400, height: 100, position: 'relative' }}>
        <LoadingOverlay visible={loading}></LoadingOverlay>
        </div> }
      { !loading && id && <>
        <Toggle currentVote={vote} onChange={(vote:number ) => onSetVote(vote)} />
        <Text size='xs'>{ upvotes } out of { upvotes + downvotes } found this interesting</Text>
      </>}
      { !loading && !id && <Text size='xs'>Something went wrong 😢</Text> }
      
    </Stack>
  )
}
