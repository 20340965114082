import { Group, Button } from '@mantine/core';
import { VotingButton, VotingButtons } from '../../interfaces/Interfaces';


export const Toggle:React.FC<VotingButtons> = ({ currentVote, onChange }) => {

  const buttons:VotingButton[] = [
    {
      label: 'Yes',
      vote: 1
    },
    {
      label: 'No',
      vote: -1
    }
  ]

  return (
    <Group spacing="sm">
      {buttons.map(({ label, vote }) => {
        return <Button 
        onClick={() => onChange(vote) }
        variant={ currentVote != vote ? 'default' : 'filled' }>{ label }</Button>
      }) }
    </Group>
  )
}
