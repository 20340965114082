import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { Voting } from './components/voting/Voting';

export const App:React.FC = () => {
  return (
    <div className="App">
      <Routes>
        <Route path="/widget/:slug" element={<Voting />} />
      </Routes>
    </div>
  );
}
